import React, { useState, useEffect } from "react";
import './Credits.css';
import axios from "axios";

function Credits() {
    const [displayedText, setDisplayedText] = useState("");
    const [glitch, setGlitch] = useState(false);
    const [showCredits, setShowCredits] = useState(false);
    const [started, setStarted] = useState(false);
    const [badgeAwarded, setBadgeAwarded] = useState(false); // Track if the badge is awarded
    const audioUrl = "https://camohax.com/downloads/misc/knock.ogg";

    const phrases = [
        "Wake up, Neo...",
        "The Matrix has you...",
        "Follow the white rabbit.",
        "Knock, knock, Neo."
    ];

    const creditsText = `
    I see you found the hidden credits...🐇
        Developed by: CamoHax
        Special Thanks to: Shaggy | Send | Sh4dow

        ** Sintrix.net **
    `;

    useEffect(() => {
        if (!started) return;

        let phraseIndex = 0;
        let charIndex = 0;
        let currentText = "";

        const playKnockSound = () => {
            const audio = new Audio(audioUrl);
            audio.play().catch((error) => console.error("Playback failed:", error));
        };

        const typeCharacter = () => {
            if (phraseIndex < phrases.length) {
                if (charIndex < phrases[phraseIndex].length) {
                    currentText += phrases[phraseIndex][charIndex];
                    setDisplayedText(currentText);
                    charIndex++;
                } else {
                    currentText += "\n";
                    setDisplayedText(currentText);
                    if (phrases[phraseIndex] === "Knock, knock, Neo.") {
                        playKnockSound(); // Play sound at the "Knock, knock, Neo." line
                    }
                    phraseIndex++;
                    charIndex = 0;
                }
            } else {
                clearInterval(typeInterval);
                setTimeout(() => {
                    setDisplayedText(""); // Clear matrix messages
                    setGlitch(true); // Trigger the glitch effect
                }, 500);
                setTimeout(() => {
                    setGlitch(false);
                    setDisplayedText(creditsText.trim());
                    setShowCredits(true);
                    awardBadge(); // Award the badge after the credits are shown
                }, 1500);
            }
        };

        const typeInterval = setInterval(typeCharacter, 100);
        return () => clearInterval(typeInterval);
    }, [started]);

    const handleStart = () => {
        setStarted(true);
    };

    const awardBadge = async () => {
        try {
            if (!badgeAwarded) {
                const token = localStorage.getItem("token");
                if (!token) return; // Ensure the user is logged in

                await axios.post(
                    "/api/auth/badge",
                    { badge: "credits_badge" }, // Send the badge identifier to the backend
                    { headers: { Authorization: `Bearer ${token}` } }
                );

                setBadgeAwarded(true); // Prevent duplicate API calls
                console.log("Credits badge awarded!");
            }
        } catch (err) {
            console.error("Error awarding badge:", err.response || err.message);
        }
    };

    return (
        <div className={`matrix-container ${glitch ? 'glitch' : ''} ${showCredits ? 'show-credits' : ''}`}>
            {!started ? (
                <div className="start-screen" onClick={handleStart}>
                    <p className="start-prompt">Click to take the red pill...</p>
                </div>
            ) : (
                <pre className="terminal-output">{displayedText}</pre>
            )}
        </div>
    );
}

export default Credits;
