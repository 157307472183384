import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle, FaExclamationTriangle, FaTimesCircle, FaUndo } from "react-icons/fa";
import axios from "axios";
import "./ModeratorPanel.css";


const ModeratorPanel = () => {
    const [activeSection, setActiveSection] = useState("users");
    const [users, setUsers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [reports, setReports] = useState([]);
    const [expandedReports, setExpandedReports] = useState({});
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();
    localStorage.setItem("redirectAfterLogin", window.location.pathname);

    // Inside your table row rendering logic
    const getStatusIcon = (status) => {
        switch (status) {
            case "green":
                return <FaCheckCircle className="status-icon green" />;
            case "yellow":
                return <FaExclamationTriangle className="status-icon yellow" />;
            case "red":
                return <FaTimesCircle className="status-icon red" />;
            default:
                return null;
        }
    };
    

    // Token Management Functions
    const verifyToken = (token) => {
        const decoded = decodeToken(token);
        if (!decoded) return false;

        const currentTime = Math.floor(Date.now() / 1000);
        return decoded.exp > currentTime; // Valid if token expiration is in the future
    };

    const decodeToken = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (err) {
            console.error("Invalid token format:", err);
            return null;
        }
    };

    const refreshAccessToken = async () => {
        try {
            const refreshToken = localStorage.getItem("refreshToken");
            const response = await axios.post("/api/auth/refresh-token", { refreshToken });
            const { accessToken } = response.data;

            localStorage.setItem("token", accessToken);
            axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
            return accessToken;
        } catch (err) {
            console.error("Failed to refresh access token. Logging out...");
            handleLogout();
            return null;
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("avatar"); // Remove avatar from localStorage on logout
        localStorage.removeItem("refreshToken");
        navigate("/login");
    };


    const ensureTokenIsValid = async () => {
        const token = localStorage.getItem("token");
        if (!token || !verifyToken(token)) {
            await refreshAccessToken();
        } else {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }
    };

    // Fetch data based on the active section
    useEffect(() => {
        const fetchData = async () => {
            await ensureTokenIsValid();
            setLoading(true);
            if (activeSection === "users") {
                axios
                    .get("/api/mod/users")
                    .then((response) => setUsers(response.data))
                    .catch(console.error)
                    .finally(() => setLoading(false));
            } else if (activeSection === "categories") {
                axios
                    .get("/api/mod/categories")
                    .then((response) => setCategories(response.data))
                    .catch(console.error)
                    .finally(() => setLoading(false));
            } else if (activeSection === "reports") {
                axios
                    .get("/api/mod/reports")
                    .then((response) => setReports(response.data))
                    .catch(console.error)
                    .finally(() => setLoading(false));
            }
        };

        fetchData();
    }, [activeSection]);

    // Toggle the expansion state of a report
    const toggleReportExpansion = (reportId) => {
        setExpandedReports((prev) => ({
            ...prev,
            [reportId]: !prev[reportId],
        }));
    };

    // User actions
    const handleUserAction = async (userId, action) => {
        await ensureTokenIsValid();
        axios
            .post(`/api/mod/users/${userId}/${action}`)
            .then(() => {
                setUsers(
                    users.map((user) =>
                        user._id === userId
                            ? { ...user, banned: action === "ban" }
                            : user
                    )
                );
            })
            .catch(console.error);
    };

    // Update category status
    const updateCategoryStatus = async (slug, status) => {
        await ensureTokenIsValid();
        axios
            .put(`/api/mod/categories/${slug}/status`, { status })
            .then(() => {
                setCategories(
                    categories.map((category) =>
                        category.slug === slug
                            ? { ...category, status }
                            : category
                    )
                );
            })
            .catch(console.error);
    };

    // Resolve reports
    const resolveReports = async (categoryId) => {
        await ensureTokenIsValid();
        axios
            .post(`/api/mod/reports/${categoryId}/resolve`)
            .then(() => {
                setReports(
                    reports.filter((report) => report._id !== categoryId)
                );
            })
            .catch(console.error);
    };

    // Filtered Data
    const filteredUsers = users.filter((user) =>
        user.username.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const filteredCategories = categories.filter((category) =>
        category.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="moderator-panel">
            <h1 className="moderator-panel-title">Moderator Panel</h1>
            <div className="moderator-panel-header">
                <button
                    className={activeSection === "users" ? "active" : ""}
                    onClick={() => setActiveSection("users")}
                >
                    Users
                </button>
                <button
                    className={activeSection === "categories" ? "active" : ""}
                    onClick={() => setActiveSection("categories")}
                >
                    Categories
                </button>
                <button
                    className={activeSection === "reports" ? "active" : ""}
                    onClick={() => setActiveSection("reports")}
                >
                    Reports
                </button>
            </div>

            {loading && <p className="text-center">Loading...</p>}

            {activeSection === "users" && (
                <div>
                    <h2>Manage Users</h2>
                    <input
                        type="text"
                        placeholder="Search users..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="search-input"
                    />
                    <table>
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Email</th>
                                <th>IP Address</th>
                                <th>Role</th>
                                <th>Verified</th>
                                <th>Banned</th>
                                <th>Created At</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsers.map((user) => (
                                <tr key={user._id}>
                                    <td>
                                        <a href={`/profile/${user.username}`} className="profile-link">
                                            {user.username}
                                        </a>
                                    </td>
                                    <td>{user.email || "N/A"}</td>
                                    <td>{user.ipAddress || "N/A"}</td>
                                    <td>{user.role}</td>
                                    <td>
                                        {user.isVerified ? (
                                            <span className="status-verified">✔</span>
                                        ) : (
                                            <span className="status-not-verified">✘</span>
                                        )}
                                    </td>
                                    <td>
                                        {user.banned ? (
                                            <span className="status-banned">✔</span>
                                        ) : (
                                            <span className="status-not-banned">✘</span>
                                        )}
                                    </td>
                                    <td>
                                        {user.createdAt
                                            ? new Date(user.createdAt).toLocaleDateString("en-US", {
                                                  year: "numeric",
                                                  month: "numeric",
                                                  day: "numeric",
                                                  hour: "numeric",
                                                  minute: "numeric",
                                              })
                                            : "N/A"}
                                    </td>
                                    <td>
                                        <div className="action-buttons">
                                            <button
                                                className={user.banned ? "unban" : "ban"}
                                                onClick={() =>
                                                    handleUserAction(user._id, user.banned ? "unban" : "ban")
                                                }
                                                disabled={user.role === "admin"}
                                            >
                                                {user.banned ? "Unban" : "Ban"}
                                            </button>
                                            {!user.isVerified && (
                                                <button
                                                    className="verify"
                                                    onClick={() => handleUserAction(user._id, "verify")}
                                                    disabled={user.role === "admin"}
                                                >
                                                    Verify
                                                </button>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {activeSection === "categories" && (
                <div>
                    <h2>Manage Categories</h2>
                    <input
                        type="text"
                        placeholder="Search categories..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="search-input"
                    />
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredCategories.map((category) => (
                                <tr key={category.slug}>
                                    <td>{category.name}</td>
                                    <td>
                                        {getStatusIcon(category.status)} {/* Show status icon */}
                                    </td>
                                        <td>
                                            <button
                                                className="status-button green"
                                                title="Set status to Working"
                                                onClick={() => updateCategoryStatus(category.slug, "green")}
                                            >
                                                <FaCheckCircle className="status-icon" />
                                            </button>
                                            <button
                                                className="status-button yellow"
                                                title="Set status to Possible Issues"
                                                onClick={() => updateCategoryStatus(category.slug, "yellow")}
                                            >
                                                <FaExclamationTriangle className="status-icon" />
                                            </button>
                                            <button
                                                className="status-button red"
                                                title="Set status to Broken"
                                                onClick={() => updateCategoryStatus(category.slug, "red")}
                                            >
                                                <FaTimesCircle className="status-icon" />
                                            </button>
                                            <button
                                                className="status-button clear"
                                                title="Clear manually set status"
                                                onClick={() => updateCategoryStatus(category.slug, "clear")}
                                            >
                                                <FaUndo className="status-icon" />
                                            </button>
                                        </td>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                </div>
            )}

            {activeSection === "reports" && (
                <div>
                    <h2>Manage Reports</h2>
                    <div className="reports-list">
                        {reports.map((report) => (
                            <div key={report._id} className="report-item">
                                <div
                                    className="report-summary"
                                    onClick={() =>
                                        toggleReportExpansion(report._id)
                                    }
                                >
                                    <span>{report.name}</span>
                                    <span>
                                        {report.reports.length} reports
                                    </span>
                                    <button className="expand-button">
                                        {expandedReports[report._id]
                                            ? "Hide Details"
                                            : "View Details"}
                                    </button>
                                </div>

                                {expandedReports[report._id] && (
                                    <div className="report-details">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Reported By</th>
                                                    <th>Reason</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {report.reports.map(
                                                    (r, index) => (
                                                        <tr
                                                            key={`${report._id}-${index}`}
                                                        >
                                                            <td>
                                                                {
                                                                    r.userId
                                                                        .username
                                                                }
                                                            </td>
                                                            <td>
                                                                {r.description ||
                                                                    "No reason provided"}
                                                            </td>
                                                            <td>
                                                                <button
                                                                    onClick={() =>
                                                                        resolveReports(
                                                                            report._id
                                                                        )
                                                                    }
                                                                >
                                                                    Resolve
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ModeratorPanel;
