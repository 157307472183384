import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import './Games.css';

function Tables() {
    const [categories, setCategories] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState(""); // Search query state
    const [categoryImages, setCategoryImages] = useState({});
    const [sortOption, setSortOption] = useState("name-asc"); // Default sorting by name (A-Z)
    localStorage.setItem("redirectAfterLogin", window.location.pathname);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get('/api/admin/categories');
                if (response.data && Array.isArray(response.data)) {
                    setCategories(response.data);
                    setFilteredCategories(response.data); // Initialize filtered categories
                } else {
                    console.error("Fetched categories are not an array:", response.data);
                }

                response.data.forEach((category) => {
                    if (category.appid) {
                        fetchCategoryLogo(parseInt(category.appid), category._id);
                    }
                });
            } catch (error) {
                console.error("Error fetching categories:", error);
            } finally {
                setLoading(false);
            }
        };

        const fetchCategoryLogo = async (steamAppId, categoryId) => {
            const imageUrl = `https://shared.fastly.steamstatic.com/store_item_assets/steam/apps/${steamAppId}/header.jpg`;

            const img = new Image();
            img.src = imageUrl;
            img.onload = () => {
                setCategoryImages(prev => ({ ...prev, [categoryId]: imageUrl }));
            };
            img.onerror = () => {
                setCategoryImages(prev => ({ ...prev, [categoryId]: "default-placeholder.jpg" }));
            };
        };

        fetchCategories();
    }, []);

    // Update the filtered categories whenever the search query or sort option changes
    useEffect(() => {
        let filtered = categories.filter(category =>
            category.name.toLowerCase().includes(searchQuery.toLowerCase())
        );

        // Sort filtered categories based on the selected option
        if (sortOption.startsWith("name")) {
            filtered.sort((a, b) =>
                sortOption === "name-asc"
                    ? a.name.localeCompare(b.name) // A-Z
                    : b.name.localeCompare(a.name) // Z-A
            );
        } else if (sortOption.startsWith("date")) {
            filtered.sort((a, b) =>
                sortOption === "date-newest"
                    ? new Date(b.createdAt) - new Date(a.createdAt) // Newest to Oldest
                    : new Date(a.createdAt) - new Date(b.createdAt) // Oldest to Newest
            );
        }

        setFilteredCategories(filtered);
    }, [searchQuery, categories, sortOption]);

    const handleCategoryClick = (category) => {
        const categorySlug = category.slug || category.name.toLowerCase().replace(/\s+/g, '-');
        navigate(`/game/${categorySlug}`);
    };

    return (
        <div className="tables-page-container">
            <Helmet>
                <title>Supported Games and Cheat Tables</title>
                <meta 
                    name="description" 
                    content={categories.map(cat => `${cat.name} cheat table`).join(", ")} 
                />
            </Helmet>

            <h2 className="tables-title">Supported Games</h2>
            <div className="search-sort-container">
                <input
                    type="text"
                    placeholder="Search games..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="search-input"
                />
                <select
                    value={sortOption}
                    onChange={(e) => setSortOption(e.target.value)}
                    className="sort-select"
                >
                    <option value="name-asc">Sort by Name (A-Z)</option>
                    <option value="name-desc">Sort by Name (Z-A)</option>
                    <option value="date-newest">Sort by Date Added (Newest to Oldest)</option>
                    <option value="date-oldest">Sort by Date Added (Oldest to Newest)</option>
                </select>
            </div>

            {loading && <p>Loading categories...</p>}

            <div className="game-grid">
                {filteredCategories.map((category) => (
                    <div key={category._id} className="game-card" onClick={() => handleCategoryClick(category)}>
                        <img
                            src={categoryImages[category._id] || "default-placeholder.jpg"}
                            alt={`${category.name} cheat table`}
                            className="game-image"
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "default-placeholder.jpg";
                            }}
                        />
                        <div className="game-details">
                            <h3 className="game-name">{category.name}</h3>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Tables;
