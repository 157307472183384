import React, { useState } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO
import './Support.css';

function Support() {
    const [openIndex, setOpenIndex] = useState(null);
    localStorage.setItem("redirectAfterLogin", window.location.pathname);

    const toggleAnswer = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const faqs = [
        { 
            question: 'What is Sintrix?', 
            answer: 'Sintrix is a platform for downloading and submitting requests for Cheat Engine tables.' 
        },
        { 
            question: 'How do I request a cheat?', 
            answer: <>Go to the <a href="/requests" className="faq-link">Requests page</a> and fill out the form to submit a new request.</> 
        },
        { 
            question: 'Is Sintrix free to use?', 
            answer: 'Yes, Sintrix is free to use. However, donations are appreciated!' 
        },
        {
            question: 'What is Cheat Engine?',
            answer: 'Cheat Engine is an open-source memory scanner and debugger that allows users to modify game variables like health, currency, and other in-game values in single-player games.'
        },
        {
            question: 'Where can I download Cheat Engine?',
            answer: <>The official Cheat Engine website at <a href="https://www.cheatengine.org/" target="_blank" rel="noopener noreferrer" className="faq-link">cheatengine.org</a> provides safe and reliable downloads.</>
        },
        {
            question: 'Can I use Cheat Engine in online games?',
            answer: 'We strongly recommend using Cheat Engine only in single-player or offline modes. Using it in online or multiplayer games can violate terms of service and lead to bans.'
        },
        {
            question: 'How do I install a Cheat Engine table?',
            answer: 'After downloading a table, open it with Cheat Engine, attach Cheat Engine to your game’s process, and follow any on-screen instructions for the table.'
        },
        {
            question: 'What should I do if a Cheat Engine table doesn’t work?',
            answer: 'If a table doesn’t load or function as expected, ensure you’re using the latest Cheat Engine version. You can also reach out on our Discord for assistance.'
        },
        {
            question: 'Why does my pointer show "???????"?',
            answer: 'This can happen if pointers are misaligned. Try checking your spare pointers, and if the issue persists, share a screenshot with us in our Discord for further help.'
        },
        {
            question: 'What does "failed to attach process" mean?',
            answer: 'If the "start table" script fails to attach, try attaching Cheat Engine to the game manually, then run the start table script again.'
        },
        {
            question: 'Can I change my email address, username or password?',
            answer: <>Yes, you can change all of this via your <a href="/dashboard" className="faq-link">dashboard.</a></>
        }
    ];    

    return (
        <div className="support-container">
            {/* Dynamic SEO with Helmet */}
            <Helmet>
                <title>Support Center - Sintrix.net</title>
                <meta name="description" content="Find answers to frequently asked questions or contact support for further assistance with Sintrix.net. Join our community and get help now!" />
            </Helmet>

            <div className="support-header">
                <h1>Support Center</h1>
            </div>
            <div className="faq-section">
                <h2>Frequently Asked Questions</h2>
                <div className="faq-list">
                    {faqs.map((faq, index) => (
                        <div
                            key={index}
                            className={`faq-item ${openIndex === index ? 'open' : ''}`}
                            onClick={() => toggleAnswer(index)}
                        >
                            <div className="faq-question">
                                {faq.question}
                            </div>
                            {openIndex === index && (
                                <div className="faq-answer">
                                    {faq.answer}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <div className="contact-section">
                <h2>Need Further Assistance?</h2>
                <p>If you can’t find the answer you’re looking for, feel free to reach out to us:</p>
                <ul>
                    <li>Email: <a href="mailto:support@sintrix.net" className="support-link">support@sintrix.net</a></li>
                    <li>For better support join our <a href="https://discord.gg/zjvdGQBrBc" target="_blank" rel="noopener noreferrer"> Discord server </a></li>
                </ul>
            </div>
        </div>
    );
}

export default Support;
