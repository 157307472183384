import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaSun, FaMoon, FaBars, FaTimes } from "react-icons/fa";
import axios from "axios";
import "./Navbar.css";

function Navbar() {
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [isAdmin, setIsAdmin] = useState(false);
    const [isMod, setIsMod] = useState(false); // Track if the user is a moderator
    const [showMenu, setShowMenu] = useState(false);
    const [showAvatarMenu, setShowAvatarMenu] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(() => localStorage.getItem("theme") === "dark");
    const [isMobile, setIsMobile] = useState(false);
    const [avatar, setAvatar] = useState(localStorage.getItem("avatar") || null);
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const navigate = useNavigate();
    const dropdownRef = useRef(null);
    const menuRef = useRef(null);
    const navRef = useRef(null);

    const getAvatarUrl = (avatar, username = "User") =>
        avatar || `https://ui-avatars.com/api/?name=${encodeURIComponent(username)}&background=random&bold=true`;

    useEffect(() => {
        if (token) {
            const decodedToken = parseJwt(token);
            setIsAdmin(decodedToken && decodedToken.role === "admin");
            setIsMod(decodedToken && (decodedToken.role === "moderator" || decodedToken.role === "admin")); // Moderators and Admins
            fetchUserData();
            fetchUnreadMessages();
        }
    }, [token]);

    useEffect(() => {
        const handleMessageRead = () => {
            fetchUnreadMessages(); // Refresh unread count when a message is read
        };
    
        // Add the event listener
        window.addEventListener("messageRead", handleMessageRead);
    
        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener("messageRead", handleMessageRead);
        };
    }, []);
    

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (err) {
            console.error("Error parsing token:", err);
            return null;
        }
    };

    const fetchUserData = async () => {
        try {
            if (!token) return;
            const res = await axios.get("/api/auth/dashboard", {
                headers: { Authorization: `Bearer ${token}` },
            });
            const fetchedAvatar = res.data.avatar || null;
            setAvatar(fetchedAvatar);
            localStorage.setItem("avatar", fetchedAvatar);
        } catch (err) {
            handleAuthError(err);
        }
    };

    const fetchUnreadMessages = async () => {
        try {
            if (!token) return;
            const res = await axios.get("/api/auth/messages/unread", {
                headers: { Authorization: `Bearer ${token}` },
            });
            setUnreadMessageCount(res.data.unreadCount || 0);
        } catch (err) {
            handleAuthError(err);
        }
    };

    useEffect(() => {
        if (token) {
            const interval = setInterval(fetchUnreadMessages, 100000);
            return () => clearInterval(interval);
        }
    }, [token]);

    const handleAuthError = async (error) => {
        if (error.response?.status === 401) {
            console.log("Access token expired, attempting refresh...");
            try {
                const refreshToken = localStorage.getItem("refreshToken");
                if (!refreshToken) throw new Error("No refresh token available");
    
                const res = await axios.post(
                    "/api/auth/refresh-token",
                    {}, // No body needed for refresh
                    {
                        headers: {
                            "x-refresh-token": refreshToken,
                        },
                    }
                );
    
                const { accessToken, refreshToken: newRefreshToken } = res.data;
    
                // Update tokens in local storage
                localStorage.setItem("token", accessToken);
                localStorage.setItem("refreshToken", newRefreshToken);
    
                // Optional: Update token in app state if applicable
                if (typeof setToken === "function") {
                    setToken(accessToken);
                }
    
                console.log("Token refreshed successfully");
            } catch (refreshError) {
                console.error("Token refresh failed:", refreshError);
                handleLogout(); // Logout if refresh fails
            }
        } else {
            console.error("API error:", error);
        }
    };
    

    const toggleTheme = () => {
        setIsDarkMode((prevMode) => {
            const newMode = !prevMode;
            if (newMode) {
                document.body.classList.add("dark");
            } else {
                document.body.classList.remove("dark");
            }
            localStorage.setItem("theme", newMode ? "dark" : "light");
    
            // Emit a custom event to notify about theme change
            window.dispatchEvent(new Event("themeChange"));
    
            return newMode;
        });
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("avatar");
        localStorage.removeItem("redirectAfterLogin");
        localStorage.removeItem("refreshToken");
        setToken(null);
        setIsAdmin(false);
        setIsMod(false);
        setShowMenu(false);
        setShowAvatarMenu(false);
        navigate("/login");
    };

    const toggleHamburgerMenu = () => {
        setShowMenu((prev) => !prev);
        setShowAvatarMenu(false);
    };

    const toggleAvatarMenu = () => {
        setShowAvatarMenu((prev) => !prev);
        setShowMenu(false);
    };

    const handleClickOutside = (event) => {
        if (navRef.current && !navRef.current.contains(event.target)) {
            setShowMenu(false);
            setShowAvatarMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <nav className="navbar" ref={navRef}>
            <div className="nav-links">
                <Link to="/" className="logo-link">
                    <img src="/sintrix4.png" alt="Logo" className="logo" />
                </Link>
                {!isMobile && (
                    <>
                        <Link to="/requests" className="nav-link">Requests</Link>
                        <Link to="/games" className="nav-link">Tables</Link>
                        <Link to="/support" className="nav-link">Support</Link>
                    </>
                )}
            </div>
            <div className="auth-links">
                {!token && !isMobile && (
                    <>
                        <Link to="/login" className="nav-link">Login</Link>
                        <Link to="/signup" className="nav-link">Signup</Link>
                    </>
                )}
                {isMobile && (
                    <button
                        ref={menuRef}
                        onClick={toggleHamburgerMenu}
                        className="hamburger-button"
                    >
                        {showMenu ? <FaTimes /> : <FaBars />}
                    </button>
                )}
                {token && (
                    <div ref={dropdownRef} className="person-icon-wrapper">
                        <div className="avatar-wrapper">
                            <img
                                src={getAvatarUrl(avatar, parseJwt(token)?.username || "User")}
                                alt="User Avatar"
                                className="avatar"
                                onClick={toggleAvatarMenu}
                            />
                            {unreadMessageCount > 0 && (
                                <div className="notification-badge">
                                    {unreadMessageCount}
                                </div>
                            )}
                        </div>
                        {showAvatarMenu && (
                            <div className="dropdownMenu">
                                <Link to="/dashboard" className="dropdownItem" onClick={() => setShowAvatarMenu(false)}>
                                    Dashboard
                                    {unreadMessageCount > 0 && (
                                        <span className="unread-count-nav">{unreadMessageCount}</span>
                                    )}
                                </Link>
                                {isAdmin && (
                                    <Link to="/admin" className="dropdownItem" onClick={() => setShowAvatarMenu(false)}>
                                        Admin Panel
                                    </Link>
                                )}
                                {isMod && (
                                    <Link to="/mod" className="dropdownItem" onClick={() => setShowAvatarMenu(false)}>
                                        Mod Panel
                                    </Link>
                                )}
                                <button onClick={toggleTheme} className="themeToggleButton">
                                    {isDarkMode ? (
                                        <>
                                            <FaSun className="theme-icon" /> Light Mode
                                        </>
                                    ) : (
                                        <>
                                            <FaMoon className="theme-icon" /> Dark Mode
                                        </>
                                    )}
                                </button>
                                <button onClick={handleLogout} className="logoutButton">
                                    Logout
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>
            {isMobile && showMenu && (
                <div ref={menuRef} className="mobile-menu">
                    <Link to="/" className="mobile-menu-item" onClick={() => setShowMenu(false)}>Home</Link>
                    <Link to="/requests" className="mobile-menu-item" onClick={() => setShowMenu(false)}>Requests</Link>
                    <Link to="/games" className="mobile-menu-item" onClick={() => setShowMenu(false)}>Tables</Link>
                    <Link to="/support" className="mobile-menu-item" onClick={() => setShowMenu(false)}>Support</Link>
                    {!token && (
                        <>
                            <Link to="/login" className="mobile-menu-item" onClick={() => setShowMenu(false)}>Login</Link>
                            <Link to="/signup" className="mobile-menu-item" onClick={() => setShowMenu(false)}>Signup</Link>
                        </>
                    )}
                </div>
            )}
        </nav>
    );
}

export default Navbar;
