import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [verificationCode, setVerificationCode] = useState(""); // For 2FA code
    const [error, setError] = useState("");
    const [show2FAModal, setShow2FAModal] = useState(false); // Toggle for 2FA modal

    // Add keyframes for animations
    const keyframes = `
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes slideUp {
        from {
            transform: translate(-50%, -60%);
            opacity: 0;
        }
        to {
            transform: translate(-50%, -50%);
            opacity: 1;
        }
    }
    `;

    // Add keyframes to a <style> tag in the DOM
    useEffect(() => {
        const styleTag = document.createElement("style");
        styleTag.innerHTML = keyframes;
        document.head.appendChild(styleTag);

        return () => {
            document.head.removeChild(styleTag);
        };
    }, []);


    const handleLogin = async () => {
        try {
            const res = await axios.post("/api/auth/login", {
                username,
                password,
                code: verificationCode || undefined, // Include 2FA code if entered
            });

            const { accessToken, refreshToken } = res.data;

            // Save tokens to localStorage
            localStorage.setItem("token", accessToken);
            localStorage.setItem("refreshToken", refreshToken);

            // Redirect the user after successful login
            const redirectTo = localStorage.getItem("redirectAfterLogin") || "/";
            console.log("Redirecting to:", redirectTo);

            // Clean up the redirectAfterLogin
            localStorage.removeItem("redirectAfterLogin");

            // Reload the page or navigate to the intended URL
            window.location.href = redirectTo;
        } catch (err) {
            const errorMessage = err.response?.data?.msg;

            if (errorMessage === "2FA code is required.") {
                setShow2FAModal(true); // Show the 2FA modal
                setError("Enter your 2FA code.");
            } else if (errorMessage === "Invalid 2FA code.") {
                setError("Invalid 2FA code. Please try again.");
            } else {
                setError(
                    errorMessage === "Your account is banned. Please contact support."
                        ? errorMessage
                        : errorMessage === "Please verify your email before logging in."
                        ? errorMessage
                        : "Invalid username or password."
                );
            }
        }
    };

    const handle2FASubmit = async () => {
        await handleLogin(); // Retry login with the 2FA code
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            show2FAModal ? handle2FASubmit() : handleLogin();
        }
    };

    // Automatically focus on the 2FA input when the modal appears
    useEffect(() => {
        if (show2FAModal) {
            const input = document.getElementById("2fa-input");
            if (input) input.focus();
        }
    }, [show2FAModal]);

    return (
        <div style={styles.container}>
            <Helmet>
                <title>Login - Sintrix.net</title>
                <meta
                    name="description"
                    content="Login to access your account on Sintrix.net. Enter your username and password to get started."
                />
            </Helmet>

            <div style={styles.formContainer}>
                <h2 style={styles.title}>Login</h2>
                {error && <p style={styles.errorMessage}>{error}</p>}
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyDown={handleKeyDown}
                    style={styles.input}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    style={styles.input}
                />
                <button onClick={handleLogin} style={styles.button}>
                    Login
                </button>
                <p style={styles.linkText}>
                    Forgot password?{" "}
                    <Link to="/forgot-password" style={styles.link}>
                        Reset it here
                    </Link>
                </p>
                <p style={styles.linkText}>
                    Don't have an account?{" "}
                    <Link to="/signup" style={styles.link}>
                        Sign up here
                    </Link>
                </p>
            </div>

            {/* 2FA Modal */}
            {show2FAModal && (
                <div style={styles.modalOverlay}>
                    <div style={styles.modal}>
                        <h3 style={styles.modalTitle}>Two-Factor Authentication</h3>
                        <p style={styles.modalDescription}>
                            Enter the 2FA code generated by your authenticator app.
                        </p>
                        <input
                            id="2fa-input"
                            type="text"
                            placeholder="2FA Code"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                            onKeyDown={handleKeyDown}
                            style={styles.input}
                        />
                        <button onClick={handle2FASubmit} style={styles.button}>
                            Submit
                        </button>
                        <button
                            onClick={() => setShow2FAModal(false)}
                            style={styles.cancelButton}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

const styles = {
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "var(--background-color)",
    },
    formContainer: {
        backgroundColor: "var(--card-background)",
        padding: "40px 20px",
        borderRadius: "16px",
        boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
        width: "100%",
        maxWidth: "400px",
        textAlign: "center",
    },
    title: {
        color: "var(--text-color)",
        marginBottom: "20px",
        fontWeight: "bold",
    },
    input: {
        padding: "12px",
        width: "100%",
        margin: "10px 0",
        color: "var(--text-color)",
        borderRadius: "6px",
        border: "1px solid #ccc",
        fontSize: "16px",
        boxSizing: "border-box",
        backgroundColor: "var(--input-color)",
    },
    button: {
        padding: "12px 20px",
        backgroundColor: "var(--button-background)",
        color: "#fff",
        border: "none",
        borderRadius: "6px",
        cursor: "pointer",
        width: "100%",
        marginTop: "10px",
        fontSize: "16px",
        transition: "background-color 0.3s ease",
    },
    cancelButton: {
        padding: "12px 20px",
        backgroundColor: "#ff4d4d", // Red for cancel button
        color: "#fff",
        border: "none",
        borderRadius: "6px",
        cursor: "pointer",
        width: "100%",
        marginTop: "10px",
        fontSize: "16px",
        transition: "background-color 0.3s ease",
    },
    errorMessage: {
        color: "red",
        marginBottom: "10px",
    },
    linkText: {
        marginTop: "20px",
        fontSize: "14px",
        color: "var(--text-color)",
    },
    link: {
        color: "var(--link-color)",
        textDecoration: "none",
        fontWeight: "bold",
        transition: "color 0.3s ease",
    },
    modalOverlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
        overflow: "hidden", // Prevent unwanted scrollbars
        animation: "fadeIn 0.3s ease",
    },    
    modal: {
        position: "absolute",
        top: "55%",
        left: "calc(50% - 8px)", // Account for half the scrollbar width
        transform: "translate(-50%, -50%)",
        backgroundColor: "var(--card-background)",
        padding: "30px",
        borderRadius: "10px",
        width: "90%",
        maxWidth: "400px",
        textAlign: "center",
        boxSizing: "border-box",
        animation: "slideUp 0.3s ease", // Add slide-up animation
    },
    modalTitle: {
        fontSize: "20px",
        marginBottom: "10px",
        color: "var(--text-color)",
    },
    modalDescription: {
        marginBottom: "20px",
        color: "var(--text-color-light)",
    },
};

export default Login;
