import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import Slider from "react-slick"; // Import react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Home.css";

function Home() {
    const navigate = useNavigate();
    const [recentGames, setRecentGames] = useState([]);
    const [gameImages, setGameImages] = useState({});

    useEffect(() => {
        // Fetch recent games
        const fetchRecentGames = async () => {
            try {
                const response = await axios.get('/api/auth/games/recent');
                if (response.data && Array.isArray(response.data)) {
                    setRecentGames(response.data);
                    // Fetch images for each game
                    response.data.forEach(game => {
                        if (game.appid) {
                            fetchGameImage(game.appid, game.id);
                        }
                    });
                } else {
                    console.error("Fetched games are not an array:", response.data);
                }
            } catch (error) {
                console.error("Error fetching recent games:", error);
            }
        };

        // Function to fetch game image
        const fetchGameImage = async (steamAppId, gameId) => {
            const imageUrl = `https://shared.fastly.steamstatic.com/store_item_assets/steam/apps/${steamAppId}/header.jpg`;

            const img = new Image();
            img.src = imageUrl;
            img.onload = () => {
                setGameImages(prev => ({ ...prev, [gameId]: imageUrl }));
            };
            img.onerror = () => {
                setGameImages(prev => ({ ...prev, [gameId]: "default-placeholder.jpg" }));
            };
        };

        fetchRecentGames();
    }, []);

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 1200, // Increase speed for smoother transitions
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000, // Slower autoplay speed
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="home-container">
            <Helmet>
                <title>Sintrix</title>
            </Helmet>
            <div className="home-content">
                <img
                    src="/logo192.png"
                    alt="HomeLogo"
                    className="homelogo"
                    onDoubleClick={() => navigate('/redpill')}
                />
                <h1 className="home-title">Welcome to Sintrix</h1>
                <hr className="separator-line" />
                <p className="home-description">
                    A platform for downloading and submitting requests for Cheat Engine tables.
                </p>
                <div className="home-buttons">
                    <Link to="/requests" className="home-button">Cheat Requests</Link>
                    <Link to="/games" className="home-button">Tables</Link>
                </div>
                <div className="recent-games-slider">
                    {Array.isArray(recentGames) && recentGames.length > 0 ? (
                        <Slider {...sliderSettings}>
                            {recentGames.map((game) => (
                                <div key={game._id} className="slider-item">
                                    {/* Make the image clickable */}
                                    <Link to={`/game/${game.slug}`}>
                                        <img
                                            src={`https://shared.fastly.steamstatic.com/store_item_assets/steam/apps/${game.appid}/header.jpg`}
                                            alt={`${game.name}`}
                                            className="game-image"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = "default-placeholder.jpg";
                                            }}
                                        />
                                    </Link>
                                    <h3 className="game-title">{game.name}</h3>
                                </div>
                            ))}
                        </Slider>
                    ) : (
                        <p className="no-games-message">No recent games available.</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Home;
