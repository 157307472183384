import React from 'react';
import './DiscordButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

function DiscordButton() {
    const handleDiscordClick = () => {
        window.open('https://discord.gg/zjvdGQBrBc', '_blank');
    };

    return (
        <div className="discord-button-wrapper">
            <div className="discord-button" onClick={handleDiscordClick}>
                <FontAwesomeIcon icon={faDiscord} size="2x" />
            </div>
            <span className="tooltip">Join us on Discord!</span>
        </div>
    );
}

export default DiscordButton;
